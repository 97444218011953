:root {
    // color
    --color-aeon: #d13d9e;
    --color-light: #fff;
    --color-light-gray: #e7e7e7;
    --color-gray: #828282;
    --color-dark: #2d2d2d;
    --color-light-yellow: #ffbf1a;
    --color-dust-red: #f5222d;
}
