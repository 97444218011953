.btn-primary,
.btn-outline {
    min-height: 40px;
}
.btn-primary:not([disabled]) {
    &,
    &:hover,
    &:focus {
        background: var(--color-aeon, --color);
        border-color: var(--color-aeon, --color);
        color: var(--color-light, --color-contrast);
    }
}
.btn-outline:not([disabled]) {
    &,
    &:hover,
    &:focus {
        background: transparent;
        color: var(--color-aeon, --color);
        border-color: var(--color-aeon, --color);
    }
}
.modal-primary {
    &.ant-modal-confirm {
        max-width: 400px;
        .ant-modal-content {
            border-radius: 8px;
        }
        .ant-modal-body {
            padding: 24px;
        }
        .ant-modal-confirm-content {
            font-size: 16px;
        }
        .ant-modal-confirm-btns {
            .ant-btn {
                height: 40px;
                border-radius: 4px;
                min-width: 114px;
            }
        }
    }
    &.modal-reject {
        max-width: 700px;
    }
}
