@import "styles/_variables.scss";
@import "styles/_components.scss";

$primaryColor: #d13d9e;
$borderColor: #e7e7e7;

#root{
    max-height:100vh !important;
    max-width: 100%;
}

.logo {
    padding: 20px 0;
    text-align: center;
    cursor: pointer;
    img {
        max-width: 211px;
        max-height: 75px;
        object-fit: cover;
    }
}

.ant-layout-sider-zero-width-trigger {
    background: $primaryColor !important;
}
.ant-layout-sider-zero-width-trigger-left {
    background: $primaryColor !important;
}
.site-layout-background {
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    //min-height: 100%;
    background: #ffffff;
    padding: 24px 40px;
    @media only screen and (max-width: 768px) {
        padding: 15px 15px;
    }
}

[data-theme="dark"] .site-layout .site-layout-background {
    background: #e5e5e5;
}

.site-layout-header {
    background-color: #ffffff !important;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
}

.site-layout {
    background: #ffffff !important;
}

.ant-layout-sider {
    padding-top: 20px;
    background: #ffffff !important;
    //min-width: 272px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $primaryColor !important;
    color: white;
    //height: 56px;
    &:after {
        border-right: none !important;
    }
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.input-common {
    background: #ffffff;
    border: 1px solid $borderColor !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
}

.input-number-common {
    background: #ffffff;
    border: 1px solid $borderColor !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    width: 100% !important;
}

.textarea-common {
    border: 1px solid $borderColor !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
}

.ql-toolbar.ql-snow {
    border: 1px solid $borderColor !important;
    background: linear-gradient(180deg, #fdfdfd 0%, #e5e5e5 100%);
    border-radius: 8px 8px 0 0;
}

.ql-container.ql-snow {
    border: 1px solid $borderColor !important;
    border-radius: 0 0 8px 8px;
}

.rangepicker-common {
    border-radius: 8px !important;
}

.select-common {
    .ant-select-selector {
        border-radius: 8px !important;
        height: 40px !important;
    }
}

.btn {
  border-radius: 4rem !important;
  padding: 0 40px !important;
  border-color: $primaryColor !important;
  color: $primaryColor !important;
}

.btn-common {
    background: $primaryColor !important;
    color: white !important;

    &:hover {
        background: $primaryColor;
        border-color: $primaryColor !important;
    }
}

.area-button {
    display: flex !important;
    justify-content: flex-end;
}

.btn-search {
    padding-left: 20px !important;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: flex-start;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-align-left {
    text-align: left;
}
.text-align-center {
    text-align: center;
}
.ant-menu-item {
    .active {
        color: white;
    }
}
.ant-form-item-label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d2d2d;
}
.ant-avatar {
    cursor: pointer;
}
.ant-skeleton {
    width: 100% !important;
}
.text-center {
    text-align: center;
}
.ant-notification-notice-with-icon .anticon-close-circle {
    display: block !important;
}
